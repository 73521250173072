import React, { useEffect, useState, useCallback } from "react";
import { IMessageProps, SearchBlock, Section } from "@lions/ui-components";
import { AiSearchBarData } from "../components.d";
import { Events, PubSubProvider } from "@/libs/pubSub";
import { Api } from "@/libs/api";
import { useDebounce } from "@/libs/hooks/useDebounce";
import { MessageFloating } from "@/components";

type Message = "SEARCH_ERROR";

export const AiSearchBarComponent = ({
  initialSearchText,
}: AiSearchBarData) => {
  const [state, setState] = useState({
    isLoading: false,
    searchText: "",
  });

  const [message, setMessage] = useState<Message | null>(null);

  const onSearch = useCallback((searchText: string) => {
    setState({ isLoading: true, searchText: formatSearchText(searchText) });

    Api.askAi(searchText)
      .then((response) => {
        if (response) {
          PubSubProvider.publishEvent(Events.AISearch, response);
        }
      })
      .catch((_) => {
        setMessage("SEARCH_ERROR");
      })
      .finally(() => {
        setState({ isLoading: false, searchText: "" });
      });
  }, []);
  const onSearchDebounced = useDebounce(onSearch, 500);

  // If an initial search text is provided, trigger the search on mount.
  useEffect(() => {
    if (initialSearchText) {
      setState({
        isLoading: true,
        searchText: formatSearchText(initialSearchText),
      });
      onSearchDebounced(formatSearchText(initialSearchText));
    }
  }, [initialSearchText, onSearchDebounced]);

  PubSubProvider.useSub(
    Events.AISearchRequest,
    (data: { search_text: string }) => {
      setState({
        isLoading: true,
        searchText: formatSearchText(data.search_text),
      });
      onSearchDebounced(data.search_text);
    }
  );
  //
  // Hide message after 4 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <Section
      title="The Work.AI"
      backgroundColor="background-black"
      variant="dark"
      fullWidth={true}
      paddingBottomOverride="spacing-small"
    >
      <SearchBlock
        focus={true}
        initialValue={state.searchText}
        placeholder={"Ask your question here"}
        cta={{
          label: "Ask",
          icon: { name: "ChevronRight" },
          loading: state.isLoading,
          variant: "primary",
        }}
        callback={onSearchDebounced}
      />

      {message && <MessageFloating {...messages[message]} isClosable />}
    </Section>
  );
};

const messages = {
  SEARCH_ERROR: {
    title: "An error occurred while searching. Please try again.",
    prominence: "inline",
    variant: "error",
  } as IMessageProps,
};
const reduceSearchText = (searchText: string | string[]): string =>
  Array.isArray(searchText) ? searchText.join(" ") : searchText;

const formatSearchText = (searchText: string | string[] | undefined): string =>
  searchText ? reduceSearchText(searchText) : "";
